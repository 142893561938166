import s1 from "../images/one.png"
import s2 from "../images/two.png"
import s3 from "../images/three.png"
import s4 from "../images/four.png"
import s5 from "../images/five.png"

let ostali = {
  Dizalice: {
    name: "Dizalice",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s1 },
      { name: "Stahl 2200kg", nub: 2, src: s2 },
      { name: "Stahl 3200kg", nub: 3, src: s3 },
      { name: "Stahl 4200kg", nub: 4, src: s4 },
      { name: "Stahl 5200kg", nub: 5, src: s5 },
    ],
  },
  "Remontovani Viljuškari": {
    name: "Remontovani Viljuškari",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s2 },
      { name: "Stahl 2200kg", nub: 2, src: s4 },
      { name: "Stahl 3200kg", nub: 3, src: s5 },
      { name: "Stahl 4200kg", nub: 4, src: s3 },
      { name: "Stahl 5200kg", nub: 5, src: s1 },
    ],
  },
  "Baterije za elektro viljuškare": {
    name: "Baterije za elektro viljuškare",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s3 },
      { name: "Stahl 2200kg", nub: 2, src: s5 },
      { name: "Stahl 3200kg", nub: 3, src: s1 },
      { name: "Stahl 4200kg", nub: 4, src: s2 },
      { name: "Stahl 5200kg", nub: 5, src: s4 },
    ],
  },
  "Transportna kolica": {
    name: "Transportna kolica",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s1 },
      { name: "Stahl 2200kg", nub: 2, src: s2 },
      { name: "Stahl 3200kg", nub: 3, src: s3 },
      { name: "Stahl 4200kg", nub: 4, src: s4 },
      { name: "Stahl 5200kg", nub: 5, src: s5 },
    ],
  },
  "Paletni viljuškari": {
    name: "Paletni viljuškari",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s1 },
      { name: "Stahl 2200kg", nub: 2, src: s2 },
      { name: "Stahl 3200kg", nub: 3, src: s3 },
      { name: "Stahl 4200kg", nub: 4, src: s4 },
      { name: "Stahl 5200kg", nub: 5, src: s5 },
    ],
  },
}

export default ostali
