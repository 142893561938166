import React, { Component } from "react"
import styled from "styled-components"
import "./Viljuskari.css"
import dizel from "../images/dizel.png"
import elektro from "../images/elektro.png"
import dizelPDF from "../images/pdf/Dizel Viljuškari.pdf"
import elektroPDF from "../images/pdf/Elektro viljuškari.pdf"

const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 220px;
`
const Content = styled.div`
  width: calc(100vw - 245px - 245px);
  margin: auto;
  @media only screen and (max-width: 1600px) {
    //width: 100%;
  }
  @media only screen and (max-width: 1100px) {
    //width: calc(100vw - 45px - 45px);
    width: 100%;
  }
`

const Float = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    text-align: center;
  }
  p {
    color: white;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
  h1 {
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 5px;
    color: white;
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: #808080;
    padding: 0 0 20px 0;
    border-bottom: 5px solid #ec4039;
    font-size: 18px;
    font-weight: 700;
    :hover {
      color: white;
      //border-bottom: 5px solid #ec4039;
    }
  }
  @media only screen and (max-width: 1700px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    p {
      margin-top: 100px !important;
    }
  }
`

const Image = styled.div`
  margin-top: 80px;
  margin-bottom: 70px;
  img {
    margin-bottom: 0;
  }
`

const Headline = styled.h1`
  font-size: 80px;
  letter-spacing: 13.33px;
  color: white;
  width: calc(100vw - 245px - 245px);
  margin: 120px auto 100px auto;
  text-transform: uppercase;
  @media only screen and (max-width: 1100px) {
    font-size: 40px;
    letter-spacing: 5px;
    width: auto;
    text-align: center;
    padding-top: 100px;
    white-space: nowrap;
  }
`

class Viljuskari extends Component {
  render() {
    return (
      <Container id="viljuskari">
        <Content>
          <Headline>{this.props.naslov}</Headline>
          <Float>
            <div>
              <p>{this.props.electro}</p>
              <Image>
                <img alt="" src={elektro} />
              </Image>
              <a href={elektroPDF} target="_blank" rel="noopener noreferrer">
                {this.props.btn1}
              </a>
            </div>
            <div>
              <p>{this.props.dizel}</p>
              <Image>
                <img alt="" src={dizel} />
              </Image>
              {/*<button>Pogledajte tehnicke karakteristike</button>*/}
              <a href={dizelPDF} target="_blank" rel="noopener noreferrer">
                {this.props.btn2}
              </a>
            </div>
          </Float>
        </Content>
      </Container>
    )
  }
}

export default Viljuskari
