import s1 from "../images/one.png"
import s2 from "../images/two.png"
import s3 from "../images/three.png"
import s4 from "../images/four.png"
import s5 from "../images/five.png"

let ostaliEng = {
  Cranes: {
    name: "Cranes",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s1 },
      { name: "Stahl 2200kg", nub: 2, src: s2 },
      { name: "Stahl 3200kg", nub: 3, src: s3 },
      { name: "Stahl 4200kg", nub: 4, src: s4 },
      { name: "Stahl 5200kg", nub: 5, src: s5 },
    ],
  },
  "Remont Forklift": {
    name: "Remont Forklifts",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s2 },
      { name: "Stahl 2200kg", nub: 2, src: s4 },
      { name: "Stahl 3200kg", nub: 3, src: s5 },
      { name: "Stahl 4200kg", nub: 4, src: s3 },
      { name: "Stahl 5200kg", nub: 5, src: s1 },
    ],
  },
  "Electro Forklifts Battery": {
    name: "Electro Forklifts Battery",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s3 },
      { name: "Stahl 2200kg", nub: 2, src: s5 },
      { name: "Stahl 3200kg", nub: 3, src: s1 },
      { name: "Stahl 4200kg", nub: 4, src: s2 },
      { name: "Stahl 5200kg", nub: 5, src: s4 },
    ],
  },
  "Transport Cart": {
    name: "Transport Cart",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s1 },
      { name: "Stahl 2200kg", nub: 2, src: s2 },
      { name: "Stahl 3200kg", nub: 3, src: s3 },
      { name: "Stahl 4200kg", nub: 4, src: s4 },
      { name: "Stahl 5200kg", nub: 5, src: s5 },
    ],
  },
  "Palette Forklifts": {
    name: "Palette Forklifts",
    items: [
      { name: "Stahl 1200kg", nub: 1, src: s1 },
      { name: "Stahl 2200kg", nub: 2, src: s2 },
      { name: "Stahl 3200kg", nub: 3, src: s3 },
      { name: "Stahl 4200kg", nub: 4, src: s4 },
      { name: "Stahl 5200kg", nub: 5, src: s5 },
    ],
  },
}

export default ostaliEng
