import React, { Component } from "react"
// import { Link } from "gatsby"
// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import Header from "../components/Header"
import Onama from "../components/Onama"
import OnamaENG from "../components/OnamaENG"
import Viljuskari from "../components/Viljuskari"
import styled from "styled-components"
import Ostali from "../components/Ostali"
import OstaliENG from "../components/OstaliENG"
import Kontakt from "../components/Kontakt"
import logo from "../images/gatsby-icon.png"
import { Link } from "gatsby"
import En from "../locales/en"
import Sr from "../locales/sr"
import "../components/Topbar.css"

const Container = styled.div`
  background: #262735;
  overflow: hidden;
`

const TopbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #262735;
  padding: 0 90px;
  height: 150px;
`

const Logo = styled.img`
  height: 74px;
  width: 133px;
  margin-bottom: 0 !important;
`

const Menu = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1000px;
  a {
    cursor: pointer;
    text-decoration: none;
    color: white !important;
  }
  @media only screen and (max-width: 1400px) {
    width: 800px;
  }
  @media only screen and (max-width: 1200px) {
    width: 600px;
  }
`

const LngBtn = styled.div`
  margin-bottom: 0 !important;
  button {
    border: none;
    background: transparent;
    outline: none;
    color: white;
    cursor: pointer;
  }
`

const TobarRes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  background: #262735;
  padding: 0 30px;
  height: 72px;
  a {
    z-index: 99;
    height: 32px !important;
  }
`

const LogoRes = styled.img`
  height: 32px;
  width: 56px;
  margin-bottom: 0 !important;
  z-index: 1000;
`

const MenuRes = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: #272735;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    font-family: "Cinzel", serif;
    color: white;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin: 25px 0;
  }
  button {
    background: transparent;
    border: none;
    outline: none;
    color: #ec4039;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
  }
`

class IndexPage extends Component {
  state = {
    language: "",
    sirina: "",
    display: false,
  }

  static contextEn = En
  static contextSr = Sr

  componentDidMount = () => {
    let language = localStorage.getItem("StimLang")
    this.setState({ language: language })
    let sirina = window.innerWidth
    this.setState({ sirina: sirina })
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    // window.removeEventListener("resize", this.updateWindowDimensions);
  }

  engLanguage = () => {
    localStorage.setItem("StimLang", "eng")
    this.setState({ language: "eng" })
    this.setState({ display: !this.state.display })
    this.setState({ active: !this.state.active })
  }

  srbLanguage = () => {
    localStorage.setItem("StimLang", "srb")
    this.setState({ language: "srb" })
    this.setState({ display: !this.state.display })
    this.setState({ active: !this.state.active })
  }

  updateWindowDimensions = () => {
    this.setState({
      sirina: window.innerWidth,
    })
  }

  openNav = () => {
    this.setState({ display: !this.state.display })
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  closeNav = () => {
    this.setState({ display: false })
    this.setState({ active: !this.state.active })
  }

  render() {
    let display = this.state.display
    let lang = this.state.language
    let sirina = this.state.sirina
    //lang files
    let menuEng = En.meni
    let menuSrb = Sr.meni
    let headerEng = En.header
    let headerSrb = Sr.header
    let kontaktEng = En.kontakt
    let kontaktSrb = Sr.kontakt
    let viljuEng = En.viljuskari
    let viljuSrb = Sr.viljuskari
    // let onamaEng = En.onama

    return (
      <Container>
        {sirina <= 1000 ? (
          <TobarRes>
            <a href="/">
              <LogoRes src={logo} />
            </a>
            <div
              className={this.state.active ? "menuBtn" : "active"}
              onClick={this.openNav}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            {display === true ? (
              <>
                <MenuRes>
                  <Link
                    to="/"
                    activeClassName="activeBtn"
                    onClick={this.closeNav}
                  >
                    {lang === "eng" ? menuEng.pocetna : menuSrb.pocetna}
                  </Link>
                  <Link
                    to="/#onama"
                    activeClassName="activeBtn"
                    onClick={this.closeNav}
                  >
                    {lang === "eng" ? menuEng.onama : menuSrb.onama}
                  </Link>
                  <Link
                    to="/#viljuskari"
                    activeClassName="activeBtn"
                    onClick={this.closeNav}
                  >
                    {lang === "eng" ? menuEng.viljuskari : menuSrb.viljuskari}
                  </Link>
                  <Link
                    to="/#ostali"
                    activeClassName="activeBtn"
                    onClick={this.closeNav}
                  >
                    {lang === "eng" ? menuEng.proizvodi : menuSrb.proizvodi}
                  </Link>
                  <Link
                    to="/#kontakt"
                    activeClassName="activeBtn"
                    onClick={this.closeNav}
                  >
                    {lang === "eng" ? menuEng.kontakt : menuSrb.kontakt}
                  </Link>
                  {lang === "srb" ? (
                    <button className="test" onClick={() => this.engLanguage()}>
                      EN
                    </button>
                  ) : (
                    <button className="test" onClick={() => this.srbLanguage()}>
                      SR
                    </button>
                  )}
                </MenuRes>
              </>
            ) : null}
          </TobarRes>
        ) : (
          <TopbarContainer>
            <Logo src={logo} />
            <Menu>
              <Link to="/">
                {lang === "eng" ? menuEng.pocetna : menuSrb.pocetna}
              </Link>
              <Link to="/#onama">
                {lang === "eng" ? menuEng.onama : menuSrb.onama}
              </Link>
              <Link to="/#viljuskari">
                {lang === "eng" ? menuEng.viljuskari : menuSrb.viljuskari}
              </Link>
              <Link to="/#ostali">
                {lang === "eng" ? menuEng.proizvodi : menuSrb.proizvodi}
              </Link>
              <Link to="/#kontakt">
                {lang === "eng" ? menuEng.kontakt : menuSrb.kontakt}
              </Link>
            </Menu>

            <LngBtn>
              <div>
                <div className="langButton">
                  {lang === "srb" ? (
                    <button className="test" onClick={() => this.engLanguage()}>
                      EN
                    </button>
                  ) : (
                    <button className="test" onClick={() => this.srbLanguage()}>
                      SR
                    </button>
                  )}
                </div>
              </div>
            </LngBtn>
          </TopbarContainer>
        )}
        <Header header={lang === "eng" ? headerEng : headerSrb} />
        {lang === "eng" ? (
          <OnamaENG head={lang === "eng" ? menuEng.onama : menuSrb.onama} />
        ) : (
          <Onama head={lang === "eng" ? menuEng.onama : menuSrb.onama} />
        )}

        <Viljuskari
          naslov={lang === "eng" ? viljuEng.naslov : viljuSrb.naslov}
          all={lang === "eng" ? viljuEng.dugme : viljuSrb.dugme}
          dizel={lang === "eng" ? viljuEng.dizel : viljuSrb.dizel}
          electro={lang === "eng" ? viljuEng.elektro : viljuSrb.elektro}
          btn1={lang === "eng" ? viljuEng.teh : viljuSrb.teh}
          btn2={lang === "eng" ? viljuEng.teh : viljuSrb.teh}
        />
        {lang === "eng" ? <OstaliENG /> : <Ostali />}
        {/*<Slider />*/}
        <Kontakt
          head={lang === "eng" ? kontaktEng.kontakt : kontaktSrb.kontakt}
          sediste={lang === "eng" ? kontaktEng.sediste : kontaktSrb.sediste}
          jedinica={lang === "eng" ? kontaktEng.jedinica : kontaktSrb.jedinica}
          prerada={lang === "eng" ? kontaktEng.prerada : kontaktSrb.prerada}
          ovde={lang === "eng" ? kontaktEng.ovde : kontaktSrb.ovde}
          preduzece={
            lang === "eng" ? kontaktEng.preduzece : kontaktSrb.preduzece
          }
          proizvodnja={
            lang === "eng" ? kontaktEng.proizvodnja : kontaktSrb.proizvodnja
          }
        />
      </Container>
    )
  }
}

export default IndexPage
