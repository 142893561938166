import React from "react"
import styled from "styled-components"
import s1 from "../images/serti.png"
import uon from "../images/UON-logo.png"

const Headline = styled.h1`
  font-size: 80px;
  letter-spacing: 13.33px;
  //line-height: 94px;
  color: white;
  text-transform: uppercase;
  width: calc(100vw - 245px * 2);
  margin: auto auto 70px auto;
  @media only screen and (max-width: 1100px) {
    font-size: 40px;
    letter-spacing: 5px;
    width: auto;
    text-align: center;
    padding-top: 100px;
  }
`

const Underline = styled.div`
  //width: 100vw;
  margin: auto;
  background: #3c3d4a;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 245px;
  p {
    margin: 0 !important;
    font-size: 25px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: white;
    //line-height: 30px;
  }
  @media only screen and (max-width: 1100px) {
    padding: 0;
    justify-content: center;
  }
`

const Container = styled.div`
  //width: calc(100vw - 245px * 2);
  //margin: auto;
  //margin-top: 220px;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100vw - 245px * 2);
  margin: 125px auto auto;
  @media only screen and (max-width: 1600px) {
    flex-direction: column;
    align-items: space-between;
    text-align: center;
    width: calc(100vw - 5vw - 5vw);
    @media only screen and (max-width: 1100px) {
    }
  }
`

const Locations = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: 50%;
  @media only screen and (max-width: 1600px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`

const Column = styled.div`
  //margin-top: 125px;
  color: white;
  p {
    font-size: 15px;
    line-height: 27px;
  }
  a {
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 15px;
    line-height: 27px;
  }
  @media only screen and (max-width: 1000px) {
    margin-bottom: 70px;
  }
`

const Map = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const MapContainer = styled.div``

const TextBold = styled.p`
  font-size: 15px;
  line-height: 27px;
  text-transform: uppercase;
  color: white;
`

const MapBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 480px;
  p {
    color: white;
    font-size: 15px;
    line-height: 27px;
    flex-basis: 70%;
  }
  img {
    height: 53px;
    width: 104px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100vw - 245px * 2);
  margin: 155px auto auto auto;
  color: white;
  p {
    font-size: 15px;
    line-height: 27px;
  }
  @media only screen and (max-width: 1100px) {
    width: 90vw;
  }
`

const Uon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  line-height: 27px;
  p {
    margin-right: 5px;
  }
  img {
    height: 9px;
    width: 24px;
    margin: 0 !important;
  }
`

const First = styled.div`
  @media only screen and (max-width: 480px) {
    display: none;
  }
`

const Second = styled.div`
  @media only screen and (min-width: 480px) {
    display: none;
  }
`

// class Kontakt extends Component {
//   render() {
const Kontakt = props => {
  return (
    <Container id="kontakt">
      <Headline>{props.head}</Headline>
      <Underline>
        <p>ABD KOMERC</p>
      </Underline>
      <Content>
        <Locations>
          <Column>
            <TextBold>{props.sediste}</TextBold>
            <p>301 Nova 3, 11 211 Beograd</p>
            <p>
              <a href="tel:381-11-332-0425">+381 11 332 0425</a>
            </p>
            <p>
              <a href="tel:381-11-332-0463">+381 11 332 0463</a>
            </p>
            <p>
              <a href="tel:381-11-332-0047">+381 11 332 0047</a>
            </p>
            <p>
              <a href="tel:381-63-200-741">+381 63 200 741</a>
            </p>
            <p>
              <a href="mailto:info@adb.co.rs">info@abd.co.rs</a>
            </p>
            <p>
              <a href="mailto:office@abd.co.rs">office@abd.co.rs</a>
            </p>
          </Column>
          <Column>
            <TextBold>{props.jedinica}</TextBold>
            <p>Leskovac - Belanovce</p>
            <p>{props.prerada}</p>
            <p>
              <a href="tel:381-16-735-774">+381 16 735 775</a>
            </p>
            <p>
              <a href="tel:381-16-735-771">+381 16 735 771</a>
            </p>
            <p>
              <a href="tel:381-63-109-1246">+381 63 109 1246</a>
            </p>
            <p>
              <a href="mailto:info@abd.co.rs">info@abd.co.rs</a>
            </p>
            <p>
              <a href="mailto:office@abd.co.rs">office@abd.co.rs</a>
            </p>
          </Column>
        </Locations>
        <Map>
          <MapContainer>
            <TextBold>{props.ovde}</TextBold>
            <First>
              <iframe
                title="abdLocation"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11310.203156419799!2d20.47975!3d44.871439!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa97ad2119c71d2e0!2sABD+komerc+doo!5e0!3m2!1sen!2srs!4v1566221521043!5m2!1sen!2srs"
                width="480"
                height="205"
                frameBorder="0"
                allowFullScreen
              />
            </First>
            <Second>
              <iframe
                title="abdLocation"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11310.203156419799!2d20.47975!3d44.871439!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa97ad2119c71d2e0!2sABD+komerc+doo!5e0!3m2!1sen!2srs!4v1566221521043!5m2!1sen!2srs"
                width="320"
                height="205"
                frameBorder="0"
                allowFullScreen
              />
            </Second>
            <MapBottom>
              <p>
                {props.preduzece}
                {""}
                <span>{props.proizvodnja}</span>
              </p>
              <img alt="" src={s1} />
            </MapBottom>
          </MapContainer>
        </Map>
      </Content>
      <Footer>
        <p>Copyright ABD 2019</p>
        <Uon>
          <p>Developed by</p>
          <a
            href="https://www.uon.rs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="" src={uon} />
          </a>
        </Uon>
      </Footer>
    </Container>
  )
  // }
}
export default Kontakt
