import React from "react"
import styled from "styled-components"
import back from "../images/header.png"

const Back = styled.img`
  width: 100%;
  min-height: 1000px;
  min-width: 1920px;
  height: auto;
  @media only screen and (max-width: 1100px) {
    min-height: 720px;
    min-width: 1280px;
  }
  @media only screen and (max-width: 600px) {
    min-height: 500px;
    min-width: 960px;
  }
`

const BackContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 1100px) {
    height: auto;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 350px;
  left: 95px;
  h1 {
    font-size: 120px;
  }
  p {
    font-size: 20px;
    line-height: 24px;
  }
  @media only screen and (max-width: 1000px) {
    top: 100px;
    width: 100%;
    left: 0;
    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 49px;
      text-align: center;
    }
    p {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
    }
  }
`

const Header = props => {
  return (
    <>
      <BackContainer>
        <Back src={back} />
      </BackContainer>
      <Content>
        <h1>ABD Komerc</h1>
        <p>{props.header}</p>
      </Content>
    </>
  )
}

export default Header
