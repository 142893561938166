import React, { Component } from "react"
import styled from "styled-components"
import "./Ostali.scss"
import ostali from "../config/OstaloData"

import s1 from "../images/others/d1.png"
import s2 from "../images/others/d2.png"
import s3 from "../images/others/d3.png"
import s4 from "../images/others/d4.png"
import s5 from "../images/others/d5.png"
import r1 from "../images/SSL25364.jpg"
import r2 from "../images/SSL25533.jpg"
import r3 from "../images/SSL25539.jpg"
import r4 from "../images/SSL25561.jpg"
import r5 from "../images/SSL25565.jpg"
import b1 from "../images/others/b1.png"
import b3 from "../images/others/b2.png"
import b2 from "../images/others/b3.png"
import p1 from "../images/others/v1.png"
import p2 from "../images/others/v2.png"
import p3 from "../images/others/v3.png"
import t1 from "../images/others/k1.png"
import t2 from "../images/others/k2.png"
import t3 from "../images/others/k3.png"

import s1r from "../images/others/d1Res1.png"
import s2r from "../images/others/d2Res1.png"
import s3r from "../images/others/d3Res1.png"
import s4r from "../images/others/d4Res1.png"
import s5r from "../images/others/d5Res1.png"

import b1r from "../images/others/b1Res1.png"
import b3r from "../images/others/b2Res1.png"
import b2r from "../images/others/b3Res1.png"
import p1r from "../images/others/v1Res1.png"
import p2r from "../images/others/v2Res1.png"
import p3r from "../images/others/v3Res1.png"
import t1r from "../images/others/k1Res1.png"
import t2r from "../images/others/k2Res1.png"
import t3r from "../images/others/k4Res1.png"

const dizalice = {
  one: { name: "Stahl 1200kg", nub: "01", src: s1 },
  two: { name: "Stahl 2200kg", nub: "02", src: s2 },
  three: { name: "Stahl 3200kg", nub: "03", src: s3 },
  four: { name: "Stahl 4200kg", nub: "04", src: s4 },
  five: { name: "Stahl 5200kg", nub: "05", src: s5 },
}

const remontovani = {
  one: { name: "Stahl 1200kg", nub: "01", src: r1 },
  two: { name: "Stahl 2200kg", nub: "02", src: r2 },
  three: { name: "Stahl 3200kg", nub: "03", src: r3 },
  four: { name: "Stahl 4200kg", nub: "04", src: r4 },
  five: { name: "Stahl 5200kg", nub: "05", src: r5 },
}

const baterije = {
  one: { name: "Stahl 1200kg", nub: "01", src: b2 },
  two: { name: "Stahl 2200kg", nub: "02", src: b1 },
  three: { name: "Stahl 3200kg", nub: "03", src: b3 },
}

const kolica = {
  one: { name: "Stahl 1200kg", nub: "01", src: t2 },
  two: { name: "Stahl 2200kg", nub: "02", src: t1 },
  three: { name: "Stahl 3200kg", nub: "03", src: t3 },
}

const paletni = {
  one: { name: "Stahl 1200kg", nub: "01", src: p1 },
  two: { name: "Stahl 2200kg", nub: "02", src: p2 },
  three: { name: "Stahl 3200kg", nub: "03", src: p3 },
}

const dizaliceRes = {
  one: { name: "Stahl 1200kg", nub: "01", src: s1r },
  two: { name: "Stahl 2200kg", nub: "02", src: s2r },
  three: { name: "Stahl 3200kg", nub: "03", src: s3r },
  four: { name: "Stahl 4200kg", nub: "04", src: s4r },
  five: { name: "Stahl 5200kg", nub: "05", src: s5r },
}

const remontovaniRes = {
  one: { name: "Stahl 1200kg", nub: "01", src: r1 },
  two: { name: "Stahl 2200kg", nub: "02", src: r2 },
  three: { name: "Stahl 3200kg", nub: "03", src: r3 },
  four: { name: "Stahl 4200kg", nub: "04", src: r4 },
  five: { name: "Stahl 5200kg", nub: "05", src: r5 },
}

const baterijeRes = {
  one: { name: "Stahl 1200kg", nub: "01", src: b2r },
  two: { name: "Stahl 2200kg", nub: "02", src: b1r },
  three: { name: "Stahl 3200kg", nub: "03", src: b3r },
}
const kolicaRes = {
  one: { name: "Stahl 1200kg", nub: "01", src: t2r },
  two: { name: "Stahl 2200kg", nub: "02", src: t1r },
  three: { name: "Stahl 3200kg", nub: "03", src: t3r },
}

const paletniRes = {
  one: { name: "Stahl 1200kg", nub: "01", src: p1r },
  two: { name: "Stahl 2200kg", nub: "02", src: p2r },
  three: { name: "Stahl 3200kg", nub: "03", src: p3r },
}

const Page = styled.div`
  width: calc(100vw - 245px * 2);
  margin: auto;
  padding-bottom: 100px;
  @media only screen and (max-width: 1700px) {
    //width: 100%;
    //margin-left: 5vw;
    //margin-right: 5vw;
    width: calc(100vw - 5vw - 5vw);
  }
`

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  //overflow: hidden;
  @media only screen and (max-width: 1550px) {
    display: none;
  }
`

const ImgContainerRes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //overflow: hidden;
  @media only screen and (min-width: 1550px) {
    display: none;
  }
`

const ImgContent = styled.div``

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //width: 100%;
  //min-width: 100%;
  border-bottom: 1px solid grey;
  margin-bottom: 100px;
  p {
    cursor: pointer;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const NavigationRes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90vw;
  overflow: auto;
  margin-bottom: 50px;
  ::-webkit-scrollbar {
    //width: 5px !important;
    height: 5px !important;
    cursor: pointer;
    background: #ec4039;
  }
  ::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    cursor: pointer;
  }
  p {
    //padding-right: 25px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 15px;
    line-height: 20px;
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (max-width: 1000px) {
    justify-content: flex-start;
  }
`

const Headline = styled.h1`
  font-size: 80px;
  letter-spacing: 13.33px;
  color: white;
  width: calc(100vw - 245px - 245px);
  margin: 120px auto 100px auto;
  text-transform: uppercase;
  @media only screen and (max-width: 1100px) {
    font-size: 40px;
    letter-spacing: 5px;
    width: auto;
    padding-top: 100px;
    text-align: center;
  }
`

class Ostali extends Component {
  state = {
    activeItem: "Dizalice",
    activeKey: "one",
    sirina: "",
  }

  updateWindowDimensions = () => {
    this.setState({
      sirina: window.innerWidth,
    })
  }

  handleClick = activeKey => {
    this.setState({ activeKey })
  }

  handleItemClick = activeItem => {
    this.setState({ activeItem })
  }

  render() {
    let { activeKey } = this.state
    let { activeItem } = this.state
    // let sirina = this.state.sirina

    console.log(ostali)
    return (
      <Page id="ostali">
        <Headline>ostali proizvodi</Headline>
        <Navigation>
          {Object.keys(ostali).map(key => {
            const {
              name,
              // items,
              // src
            } = ostali[key]
            return (
              <div
                onClick={() => {
                  this.handleItemClick(key)
                  // this.handleClick(key)
                }}
                className={`navigation ${activeItem === key && "activeNav"}`}
              >
                <p>{name}</p>
              </div>
            )
          })}
        </Navigation>
        <NavigationRes>
          {Object.keys(ostali).map(key => {
            const {
              name,
              // items,
              // src
            } = ostali[key]
            return (
              <div
                onClick={() => {
                  this.handleItemClick(key)
                  // this.handleClick(key)
                }}
                className={`navigationRes ${activeItem === key &&
                  "activeNavRes"}`}
              >
                <p>{name}</p>
              </div>
            )
          })}
        </NavigationRes>
        {activeItem === "Dizalice" ? (
          <ImgContainer>
            {Object.keys(dizalice).map(key => {
              const { src, nub } = dizalice[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`image ${activeKey === key && "activeImage"}`}
                    >
                      <div className="imageCnt">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lineNoRes" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainer>
        ) : null}
        {activeItem === "Remontovani Viljuškari" ? (
          <ImgContainer>
            {Object.keys(remontovani).map(key => {
              const { src, nub } = remontovani[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`image ${activeKey === key && "activeImage"}`}
                    >
                      <div className="imageCnt">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lineNoRes" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainer>
        ) : null}
        {activeItem === "Baterije za elektro viljuškare" ? (
          <ImgContainer>
            {Object.keys(baterije).map(key => {
              const { src, nub } = baterije[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`image ${activeKey === key && "activeImage"}`}
                    >
                      <div className="imageCnt">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lineNoRes" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainer>
        ) : null}
        {activeItem === "Transportna kolica" ? (
          <ImgContainer>
            {Object.keys(kolica).map(key => {
              const { src, nub } = kolica[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`image ${activeKey === key && "activeImage"}`}
                    >
                      <div className="imageCnt">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lineNoRes" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainer>
        ) : null}
        {activeItem === "Paletni viljuškari" ? (
          <ImgContainer>
            {Object.keys(paletni).map(key => {
              const { src, nub } = paletni[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`image ${activeKey === key && "activeImage"}`}
                    >
                      <div className="imageCnt">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lineNoRes" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainer>
        ) : null}
        {/*test*/}
        {/*<h1>res</h1>*/}
        {activeItem === "Dizalice" ? (
          <ImgContainerRes>
            {Object.keys(dizaliceRes).map(key => {
              const { src, nub } = dizaliceRes[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`imageRes ${activeKey === key &&
                        "activeImageRes"}`}
                    >
                      <div className="imageCntRes">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lines" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainerRes>
        ) : null}
        {activeItem === "Remontovani Viljuškari" ? (
          <ImgContainerRes>
            {Object.keys(remontovaniRes).map(key => {
              const { src, nub } = remontovaniRes[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`imageRes ${activeKey === key &&
                        "activeImageRes"}`}
                    >
                      <div className="imageCntRes">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lines" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainerRes>
        ) : null}
        {activeItem === "Baterije za elektro viljuškare" ? (
          <ImgContainerRes>
            {Object.keys(baterijeRes).map(key => {
              const { src, nub } = baterijeRes[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`imageRes ${activeKey === key &&
                        "activeImageRes"}`}
                    >
                      <div className="imageCntRes">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lines" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainerRes>
        ) : null}
        {activeItem === "Transportna kolica" ? (
          <ImgContainerRes>
            {Object.keys(kolicaRes).map(key => {
              const { src, nub } = kolicaRes[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`imageRes ${activeKey === key &&
                        "activeImageRes"}`}
                    >
                      <div className="imageCntRes">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lines" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainerRes>
        ) : null}
        {activeItem === "Paletni viljuškari" ? (
          <ImgContainerRes>
            {Object.keys(paletniRes).map(key => {
              const { src, nub } = paletni[key]
              return (
                <div>
                  <ImgContent>
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`imageRes ${activeKey === key &&
                        "activeImageRes"}`}
                    >
                      <div className="imageCntRes">
                        <img alt="" id="slika" src={src} />
                      </div>
                      <div className="lines" />
                      <p>{nub}</p>
                    </div>
                  </ImgContent>
                </div>
              )
            })}
          </ImgContainerRes>
        ) : null}
      </Page>
    )
  }
}

export default Ostali
