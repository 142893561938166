import React, { Component } from "react"
import styled from "styled-components"

const object = [
  {
    item:
      "ABD KOMERC DOO is a leading manufacturer of forklifts in Serbia, founded in 1993. year. Covers over 80% of the market.",
  },
  { item: "We have provided excellent and spare parts warehouse." },
  {
    item:
      "We are fully equipped for the production and servicing of diesel and electric forklifts.",
  },
  {
    item:
      "Manufacture and sell a large number of spare parts for electric and diesel forklifts.",
  },
  {
    item:
      "Service all types of electric and diesel forklifts, which are represented in the Serbian market. We service their parts and components.",
  },
  {
    item:
      "We sell and service batteries for electric forklifts of various manufacturers.",
  },
  {
    item:
      "We have excellent service, equipped with all the necessary tools and machinery.",
  },
  { item: "We sell and service all types of pallet trucks." },
  {
    item:
      "In the service are well-trained technicians and specialized electrician to repair forklifts. Service also performs on the field.",
  },
  {
    item:
      "Our customers and users of services are the leading domestic firms (Imlek ad, Velefarm, Belgrade ad, Printing office Borba, Tehnogas, Aleva, Agroekonomik, oil refinery, the Army of Serbia, Politika, Drenik ND, Milšped, as well as many other smaller private companies.",
  },
  {
    item:
      "Has its own train service, which we transport services forklifts to the desired place.",
  },
  { item: "Method and term of payment are by appointment." },
  {
    item:
      "Guarantee the work performed is determined depending on the type of repair.",
  },
]

const Page = styled.div`
  //background: #262735;
  padding-bottom: 120px;
  border-bottom: 1px solid #3c3d4a;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100vw - 245px - 245px);
  margin: auto;
  @media only screen and (max-width: 1700px) {
    width: 100%;
    margin: auto;
    justify-content: center;
  }
`

const Headline = styled.h1`
  font-size: 80px;
  letter-spacing: 13.33px;
  color: white;
  width: calc(100vw - 245px - 245px);
  margin: 120px auto 100px auto;
  text-transform: uppercase;
  @media only screen and (max-width: 1100px) {
    font-size: 40px;
    letter-spacing: 5px;
    width: auto;
    text-align: center;
    padding-top: 100px;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  height: 150px;
  @media only screen and (max-width: 1700px) {
    //width: 80%;
    margin: auto auto 20px auto;
  }
  @media only screen and (max-width: 600px) {
    height: auto;
  }
`

const Item = styled.div`
  min-height: inherit;
  height: inherit;
  width: 600px;
  background: #3c3d4a;
  display: flex;
  align-self: center;
  margin-left: 5px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  p {
    width: 588px;
    font-size: 15px;
    color: white;
    margin: 25px !important;
  }
  @media only screen and (max-width: 1700px) {
    //width: 80%;
    margin-left: auto;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: auto;
    border-bottom: 3px solid #ec4039;
    p {
      min-width: 100vw;
      width: 100%;
      padding: 30px;
    }
  }
`

const Line = styled.div`
  width: 7px;
  min-height: inherit;
  height: inherit;
  background: #ec4039;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

class OnamaEng extends Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <Page id="onama">
        <Headline>{this.props.head}</Headline>
        <Container>
          {object.map(({ i, item }) => (
            <Card>
              <Item>
                <Line />
                <p key={i}>{item}</p>
              </Item>
            </Card>
          ))}
        </Container>
      </Page>
    )
  }
}

export default OnamaEng
