import React, { Component } from "react"
import styled from "styled-components"

const object = [
  {
    item:
      "ABD KOMERC DOO je vodeći proizvođač viljuškara u Srbiji osnovan  1993. godine. Pokriva preko 80% tržišta.",
  },
  { item: "Raspolažemo i odlično snabdevenim magacinom rezervnih delova." },
  {
    item:
      "Potpuno smo opremljeni za proizvodnju i servisiranje elektro i dizel viljuškara.",
  },
  {
    item:
      "Proizvodimo i prodajemo veliki broj rezervnih delova za elektro i dizel viljuškare.",
  },
  {
    item:
      "Servisiramo sve tipove elektro i dizel viljuškara koji su zastupljeni na srpskom tržištu. Vršimo servis njihovih delova i sklopova.",
  },
  {
    item:
      "Prodajemo i servisiramo baterije za elektro viljuškare raznih proizvođača.",
  },
  {
    item:
      "Raspolažemo odlično opremljenim servisom sa svim potrebnim alatima i mašinama.",
  },
  { item: "Prodajemo i servisiramo sve tipove paletnih kolica." },
  {
    item:
      "U servisu rade dobro obučeni mehaničari i električari specijalizovani za popravke viljuškara. Servisiranje takođe obavljamo i na terenu.",
  },
  {
    item:
      "Naši kupci i korisnici usluga su vodeće domaće firme (Imlek ad, Velefarm, Beograd ad, Štamparija Borba, Tehnogas, Aleva, Agroekonomik, Rafinerija nafte, Vojska Srbije, Politika, Drenik ND, Milšped, kao i mnoga druga manja privatna preduzeća.",
  },
  {
    item:
      "Posedujemo sopstvenu šlep službu kojom vršimo usluge prevoza viljuškara do željenog mesta.",
  },
  { item: "Način i rok plaćanja su po dogovoru." },
  {
    item:
      "Garancija na izvršene radove se određuje u zavisnosti od vrste remonta.",
  },
]

const Page = styled.div`
  //background: #262735;
  padding-bottom: 120px;
  border-bottom: 1px solid #3c3d4a;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100vw - 245px - 245px);
  margin: auto;
  @media only screen and (max-width: 1700px) {
    width: 100%;
    margin: auto;
    justify-content: center;
  }
`

const Headline = styled.h1`
  font-size: 80px;
  letter-spacing: 13.33px;
  color: white;
  width: calc(100vw - 245px - 245px);
  margin: 120px auto 100px auto;
  text-transform: uppercase;
  @media only screen and (max-width: 1100px) {
    font-size: 40px;
    letter-spacing: 5px;
    width: auto;
    text-align: center;
    padding-top: 100px;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  height: 150px;
  @media only screen and (max-width: 1700px) {
    //width: 80%;
    margin: auto auto 20px auto;
  }
  @media only screen and (max-width: 600px) {
    height: auto;
  }
`

const Item = styled.div`
  min-height: inherit;
  height: inherit;
  width: 600px;
  background: #3c3d4a;
  display: flex;
  align-self: center;
  margin-left: 5px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  p {
    width: 588px;
    font-size: 15px;
    color: white;
    margin: 25px !important;
  }
  @media only screen and (max-width: 1700px) {
    //width: 80%;
    margin-left: auto;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: auto;
    border-bottom: 3px solid #ec4039;
    p {
      min-width: 100vw;
      width: 100%;
      padding: 30px;
    }
  }
`

const Line = styled.div`
  width: 7px;
  min-height: inherit;
  height: inherit;
  background: #ec4039;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

class Onama extends Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <Page id="onama">
        <Headline>{this.props.head}</Headline>
        <Container>
          {object.map(({ i, item }) => (
            <Card>
              <Item>
                <Line />
                <p key={i}>{item}</p>
              </Item>
            </Card>
          ))}
        </Container>
      </Page>
    )
  }
}

export default Onama
